.coming-soon-tooltip-container .ant-tooltip-inner {
    padding: 0px;
    background: #ffffff;
    box-shadow: rgba(141, 150, 170, 0.4) 0px 1px 6px 0px;
    width: 380px;    
    color: #424242;
    margin: auto;
}
.coming-soon-tooltip-container .ant-tooltip-arrow {
    display: none;
}

.ant-tooltip.coming-soon-tooltip-container {
    max-width: 380px !important;
}